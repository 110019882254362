import React, { FC } from 'react';

export const UserSignup: FC<{}> = () => {

  return (
    <div>
      
    </div>
  );
};
